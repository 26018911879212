body {
  background-color: #2b323d!important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.textWhite{
color: white
}
.dashboardRef{
  font-size: 15px;
  color: white;
  text-decoration-line: underline;
}
.dashboardInput{
  width: 100%
}
.mainVideoRow {

  /* height: 650px; */

}
.mainVideoColOne{
  /* background-color: black; */
 justify-content: center;
    display: flex !important;
    align-items: center; 
    
}
.mainVideoColTwo{
  background-color: white;
}
.videosElement{
  /* width: 100%; */
  width: 100%;
  /* width: 260px; */
  /* width: auto; */
  border-radius: 10px;
}
.bigVideo{
  width: 100%;
    padding: 2%;
}
#video_box {
  position:relative;
}
#video_overlays1, #video_overlays2, #video_overlays3, #video_overlays4, #video_overlays5, #video_overlays6, #video_overlays7, #video_overlays8 {
  /* position:absolute;
  width:160px;
  min-height:40px;
  background-color:#dadada;
  z-index:300000;
  bottom:10px;
  left:10px;
  text-align:center; */
  position: absolute;
  width: 10%;
  min-height: 18px;
  background-color: #dadada;
  z-index: 300000;
  /* bottom: -11%; */
  /* left: 117px; */
  right: 6%;
  top: 4%;
  text-align: center;
  display: none;
}
.secondVideoCol{
  /* background-color: purple; */
  /* padding-right: 1%; */
  margin: 1%;
}
#navbarNavDropdown{
  padding-left: 70%;
}
.videoText{
  font-size: xx-large;
    color: white;
}
.avatar{
  float: left;
  /* overflow: auto; */
  /* padding-left: 0%; */
  margin-left: 8%;
}
.avatarText{
  float: left;
  overflow: auto;
  padding-top: 4%;
  padding-left: 1%;
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout {
  
  background-color: #2b323d
}
.mainBackground{
  background-color: #2b323d
}
.ant-layout {
 
  background-color: #2b323d !important; 
  /* height: 835px !important; */
  height: 100% !important;
    overflow: hidden !important;
}

.firstCol{
  background-color: #656b72;
    padding: 0;
    padding-top: 1%;
    border-radius: 5px;
}
/* .firstCol:hover{
  background-color: black;
} */
.barText{
  margin:  0 ;
}
.iconImages{

}
.siderAudio{
width: 18%;
}
.siderVideo{
  width: 40%;
  padding-left: 5px;
}
.siderLeave{
  width: 30%;
  padding-left: 5px;
}
.siderHeading{
  float: left;
    padding-left: 12%;
}
.ant-list-item {
  /* padding: 0 !important; */
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  float: none !important;
}
.ant-collapse-borderless{
  background-color: #efefef !important;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: none !important;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: none !important
}
/* #tileNameBar1,#tileNameBar2,#tileNameBar3,#tileNameBar4,#tileNameBar5,#tileNameBar6,#tileNameBar7,#tileNameBar8{
  background-color:black;
  position: absolute;
  width: 95%;
  height: 22%;
  z-index: 300000;
  top: 73%;
  border-radius: 3px;
  text-align: center;
  opacity: 0.8;
  margin-left: 0%;
  display: none;
} */
#tileNameBarSpan1,#tileNameBarSpan2,#tileNameBarSpan3,#tileNameBarSpan4,#tileNameBarSpan5,#tileNameBarSpan6,#tileNameBarSpan7,#tileNameBarSpan8{
  /* padding-left: 1%; */
    padding-top: 2%;
    float: left;
}

/* #gtileNameBar1,#gtileNameBar2,#gtileNameBar3,#gtileNameBar4,#gtileNameBar5,#gtileNameBar6,#gtileNameBar7,#gtileNameBar8{
  background-color: black;
    position: absolute;
    width: 50%;
    height: 16%;
    z-index: 300000;
    top: 81%;
    border-radius: 5px;
    text-align: center;
    opacity: 0.8;
    margin-left: 0%;
    display: none;
    font-size: 20px;
    max-height: 21%;
    min-height: 18%;
} */
#gtileNameBarSpan1,#gtileNameBarSpan2,#gtileNameBarSpan3,#gtileNameBarSpan4,#gtileNameBarSpan5,#gtileNameBarSpan6,#gtileNameBarSpan7,#gtileNameBarSpan8{
  padding-left: 5%;
    padding-top: 2%;
    float: left;
}
#globalTileNameBarSpan{
  padding-left: 5%;
  padding-top: 2%;
  float: left;
}
/* #globalTileNameBar{
  background-color: black;
  position: absolute;
  width: 50%;
  height: 11%;
  z-index: 300000;
  top: 88%;
  border-radius: 6px;
  text-align: center;
  opacity: 0.8;
  margin-left: 0%;
  display: none;
  font-size: 32px;
} */
.badgeParticipents{
  background-color: green;
}
#root{
  height: 100% !important;
}
.logo-ref {
  padding: 0%;
}

.vimeo-container {
  /* position: fixed; */
  /* width: 100%; */
  /* height: 100%; */
  overflow: hidden;
  max-width: 100%;
 }
 
 .vimeo-container .overlay{
  position: absolute;
  width: 100%;
  height: 20%;
  background: #1f262f;
  opacity: 0.5;
  z-index: 999;
  bottom: 1%;
  display: none;
 }
 
 
 .vimeo-container .overlay .text-container{
     width:100%;
     text-align:center;
 }
 
 .vimeo-container .overlay .text-container h5{
     color:#FFF;
     text-transform:uppercase;
 }
 
 .vimeo-container iframe,
 .vimeo-container object,
 .vimeo-container embed {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
 }
 .site-layout-background-footer{
  padding: 0px;
  width: 70%;
  /* background: #fff; */
  background: #2b323d !important;
  margin-left: 10%;
}
.participantStyle{
  margin-top: 4%;
}
.videStyleFooter{
  margin-top: 6%;
}
.speakerStyleFooter{
  margin-top: 3%;
}
@media only screen  and (min-width:800px) and (max-width:999px)
{
  .site-layout-background-footer{
    padding: 0px;
    width: 100%;
    /* background: #fff; */
    background: #2b323d !important;
    margin-left: 0%;
  }
}
@media only screen  and (min-width:1000px) and (max-width:1149px)
{
  .site-layout-background-footer{
    padding: 0px;
    width: 85%;
    /* background: #fff; */
    background: #2b323d !important;
    margin-left: 3%;
  }
}
@media only screen  and (min-width:1150px) and (max-width:1239px)
{
  .site-layout-background-footer{
    padding: 0px;
    width: 74%;
    /* background: #fff; */
    background: #2b323d !important;
    margin-left: 10%;
  }
}
@media only screen  and (min-width:1240px) and (max-width:1400px)
{
  .site-layout-background-footer{
    padding: 0px;
    width: 68%;
    /* background: #fff; */
    background: #2b323d !important;
    margin-left: 15%;
  }
}
@media only screen  and (min-width:1401px) and (max-width:14000px)
{
  .site-layout-background-footer{
    padding: 0px;
    width: 60%;
    /* background: #fff; */
    background: #2b323d !important;
    margin-left: 21%;
  }
}

.vimeo-container-speaker {
  /* position: fixed; */
  /* width: 100%; */
  /* height: 100%; */
  overflow: hidden;
  max-width: 100%;
 }
 
 .vimeo-container-speaker .overlay{
  position: absolute;
  width: 100%;
  height: 25%;
  background: #1f262f;
  opacity: 0.5;
  z-index: 999;
  bottom: 2%;
  display: none;
 }
 .vimeo-container-speaker .overlay .text-container{
     width:100%;
     text-align:center;
 }
 
 .vimeo-container-speaker .overlay .text-container h6{
     color:#FFF;
     text-transform:uppercase;
 }
 
 .vimeo-container-speaker iframe,
 .vimeo-container-speaker object,
 .vimeo-container-speaker embed {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
 }

 .vimeo-container-global {
  /* position: fixed; */
  width: 60%;
  /* height: 100%; */
  overflow: hidden;
  max-width: 100%;
 }
 
 .vimeo-container-global .overlay{
  position: absolute;
  width: 60%;
  height: 20%;
  background: #1f262f;
  opacity: 0.5;
  z-index: 999;
  bottom: 0%;
  display: none;
 }
 .vimeo-container-global .overlay .text-container{
     width:100%;
     text-align:center;
 }
 
 .vimeo-container-global .overlay .text-container h3{
     color:#FFF;
     text-transform:uppercase;
 }
 
 .vimeo-container-global iframe,
 .vimeo-container-global object,
 .vimeo-container-global embed {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
 }
 .globlaIcon{
  padding-left: 5%;
  padding-top: 2%;
  float: left;
  font-size: 3vmax;
  color: white !important;
 }
 .speakerIcon{
  padding-left: 5%;
  padding-top: 4%;
  float: left;
  font-size: 1vmax;
  color: white !important;
 }
 .speakerIconSpeaker{
  padding-left: 0%;
  padding-top: 4%;
  float: left;
  font-size: 1vmax;
  color: white !important;
 }
 .galleryIcon{
  padding-left: 5%;
  padding-top: 2%;
  float: left;
  font-size: 2vmax;
  color: white !important;
 }
 .siderList{
  padding-left: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
    margin: 0 !important;
 }
 .siderIcon{
  overflow: auto;
  padding-top: 3%;
 }
 .loadingLayout{
  background-color: black !important;
  position: absolute;
  z-index: 1000;
  opacity: 0.8;
  width: 100%;
  display: block;
  color: white;
 }
 .loadingLayoutContent{
  display: flex;
  justify-content: center;
  flex-direction: column;
 }
 .ant-collapse-content > .ant-collapse-content-box {
  padding-left: 0px !important;
}
.avatar_Gallery{
  position: relative;
    width: 100%;
    height: 100%;
    background-color: gainsboro !important;
    border-radius: 13px;
    background-color: #ffffff;
    background-image: linear-gradient(right, #f4f5fb, #ffffff);
    text-align: center;
    color: #1177be;
    flex-direction: column;
    display: flex;
    justify-content: center;
    font-size: -webkit-xxx-large;
    display: none;
    text-transform: uppercase;
}
.justify-content-center{
  width: 100% !important;
}